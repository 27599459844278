import { ReferencesInfo as ReferenceInfo } from '../redux/types'

export function titleCase(str: string) {
	const words = str.toLowerCase().split(' ')
	for (var i = 0; i < words.length; i++) {
		words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1)
	}
	return words.join(' ')
}

// Helper functions moved outside for reuse
const formatAuthorsAPA = (authors: string[]): string => {
	return authors
		.map((author, index) => {
			const names = author.trim().split(' ')
			const lastName = names.pop()
			const initials = names.map((n) => n.charAt(0).toUpperCase() + '.')

			// Handle single-name authors
			if (names.length === 0) {
				// No last name provided, use the first name as last name
				return `${lastName}`
			}

			const formattedName = `${lastName}, ${initials.join(' ')}`
			if (index === authors.length - 1 && authors.length > 1) {
				return `& ${formattedName}`
			}
			return formattedName
		})
		.join(', ')
}

const formatAuthorsHarvard = (authors: string[]): string => {
	return authors
		.map((author, index) => {
			const names = author.trim().split(' ')
			const lastName = names.pop()
			const firstNames = names.join(' ')

			// Handle single-name authors
			if (names.length === 0) {
				return `${lastName}`
			}

			const formattedName = `${lastName}, ${firstNames}`
			if (index === authors.length - 1 && authors.length > 1) {
				return `and ${formattedName}`
			}
			return formattedName
		})
		.join(', ')
}

const formatAuthorsMLA = (authors: string[]): string => {
	const formattedAuthors = authors.map((author) => {
		const names = author.trim().split(' ')
		if (names.length === 1) {
			// Single-name author
			return names[0]
		}
		const lastName = names.pop()
		const firstNames = names.join(' ')
		return `${lastName}, ${firstNames}`
	})

	if (formattedAuthors.length === 1) {
		return formattedAuthors[0]
	} else if (formattedAuthors.length === 2) {
		return `${formattedAuthors[0]} and ${formattedAuthors[1]}`
	} else {
		return `${formattedAuthors[0]}, et al.`
	}
}

const formatAuthorsChicago = (authors: string[]): string => {
	const formattedAuthors = authors.map((author) => {
		const names = author.trim().split(' ')
		if (names.length === 1) {
			// Single-name author
			return names[0]
		}
		const lastName = names.pop()
		const firstNames = names.join(' ')
		return `${lastName}, ${firstNames}`
	})

	if (formattedAuthors.length === 1) {
		return formattedAuthors[0]
	} else {
		return `${formattedAuthors[0]} et al.`
	}
}

const formatAuthorsIEEE = (authors: string[]): string => {
	return authors
		.map((author) => {
			const names = author.trim().split(' ')
			if (names.length === 1) {
				// Single-name author
				return names[0]
			}
			const lastName = names.pop()
			const initials = names.map((n) => n.charAt(0).toUpperCase() + '.')
			return `${initials.join(' ')} ${lastName}`
		})
		.join(', ')
}

// New function to return the reference as a string
export const formatReferenceAsString = (style: string | null, info: ReferenceInfo): string => {
	if (!style) {
		return ''
	}
	let { url, title, authors, publishedDate } = info

	const shortenedAuthors = authors?.length && authors.length > 7 ? authors.slice(0, 7) : authors

	// Convert the published date to the desired format
	const date = publishedDate ? new Date(publishedDate) : new Date()
	const year = date.getFullYear()
	const monthName = date.toLocaleString('default', { month: 'long' })
	const monthNumeric = (date.getMonth() + 1).toString().padStart(2, '0')
	const day = date.getDate()

	let formattedReference = ''

	switch (style) {
		case 'APA_7':
			formattedReference = `${formatAuthorsAPA(shortenedAuthors ?? [])} (${year}). ${title}. Retrieved from ${url}.`
			break
		case 'HARVARD':
			formattedReference = `${formatAuthorsHarvard(
				shortenedAuthors ?? []
			)} (${year}) ${title}. Available at: ${url} (Accessed: ${day} ${monthName} ${year}).`
			break
		case 'MLA_9':
			formattedReference = `${formatAuthorsMLA(
				shortenedAuthors ?? []
			)}. "${title}." ${title}, ${day} ${monthName} ${year}, ${url}.`
			break
		case 'CHICAGO':
			formattedReference = `${formatAuthorsChicago(
				shortenedAuthors ?? []
			)}. "${title}." Last modified ${monthName} ${day}, ${year}. ${url}.`
			break
		case 'IEEE':
			formattedReference = `${formatAuthorsIEEE(
				shortenedAuthors ?? []
			)}, "${title}," ${title}, accessed ${monthNumeric}/${day}/${year}. [Online]. Available: ${url}.`
			break
		default:
			throw new Error(`Unsupported reference style: ${style}`)
	}

	return formattedReference
}

// Original function remains unchanged but uses the helper functions
export const formatReference = (style: string, info: ReferenceInfo, index: number) => {
	let { url, title, authors, publishedDate } = info

	const shortenedAuthors = authors?.length && authors.length > 7 ? authors.slice(0, 7) : authors

	// Convert the published date to the desired format
	const date = publishedDate ? new Date(publishedDate) : new Date()
	const year = date.getFullYear()
	const monthName = date.toLocaleString('default', { month: 'long' })
	const monthNumeric = (date.getMonth() + 1).toString().padStart(2, '0')
	const day = date.getDate()

	let formattedReference = <></>

	switch (style) {
		case 'APA_7':
			formattedReference = (
				<>
					{`${formatAuthorsAPA(shortenedAuthors ?? [])} (${year}). `}
					<i>{`${title}.`}</i> Retrieved from{' '}
					<a href={url} target="_blank" className="text-link">
						{url}
					</a>
					.
				</>
			)
			break
		case 'HARVARD':
			formattedReference = (
				<>
					{`${formatAuthorsHarvard(shortenedAuthors ?? [])} (${year}) ${title}. Available at: `}
					<a href={url} target="_blank" className="text-link">
						{url}
					</a>{' '}
					(Accessed: {day} {monthName} {year}).
				</>
			)
			break
		case 'MLA_9':
			formattedReference = (
				<>
					{`${formatAuthorsMLA(shortenedAuthors ?? [])}. "${title}." `}
					<i>{title}</i>, {day} {monthName} {year},{' '}
					<a href={url} target="_blank" className="text-link">
						{url}
					</a>
					.
				</>
			)
			break
		case 'CHICAGO':
			formattedReference = (
				<>
					{`${formatAuthorsChicago(shortenedAuthors ?? [])}. "${title}." Last modified ${monthName} ${day}, ${year}. `}
					<a href={url} target="_blank" className="text-link">
						{url}
					</a>
					.
				</>
			)
			break
		case 'IEEE':
			formattedReference = (
				<>
					{`${formatAuthorsIEEE(shortenedAuthors ?? [])}, "`}
					{`${title}," `}
					<i>{title}</i>, {`accessed ${monthNumeric}/${day}/${year}`}. [Online]. Available:{' '}
					<a href={url} target="_blank" className="text-link">
						{url}
					</a>
					.
				</>
			)
			break
		default:
			throw new Error(`Unsupported reference style: ${style}`)
	}

	return <div key={index}>{formattedReference}</div>
}

export const getReferenceText = (reference: ReferenceInfo, index: number, citationFormat?: string) => {
	switch (citationFormat) {
		case 'MLA_9':
			return parseCiteReferenceInMLAFormat(reference)

		case 'APA_7':
			return parseCiteReferenceInAPAFormat(reference)

		case 'HARVARD':
			return parseCiteReferenceInHarvardFormat(reference)

		case 'IEEE':
			return parseCiteReferenceInIEEEFormat(reference, index + 1)

		case 'CHICAGO':
			return parseCiteReferenceInChicagoFormat(reference)

		default:
			return parseCiteReferenceInAPAFormat(reference)
	}
}

/**
 * Harvard Reference Format
 * @param reference
 */
export function parseCiteReferenceInHarvardFormat(reference: ReferenceInfo) {
	const { authors, publishedDate, title, url, publisher, page } = reference

	let refText = ''
	const parsedAuthors = parseHarvardAuthors(authors)

	let sourceInfo = ''
	if (publisher) {
		sourceInfo += publisher
	}

	if (page) {
		sourceInfo = `${sourceInfo}, p. ${page}`
	}

	if (parsedAuthors) {
		refText += `${parsedAuthors}. `
		refText += `${parseHarvardDate(publishedDate)}. `

		if (!sourceInfo) {
			refText += `${title}. `
		} else {
			refText += `"${title}," `
			refText += `${sourceInfo}. `
		}
	} else {
		if (!sourceInfo) {
			refText += `${title} `
			refText += `${parseHarvardDate(publishedDate)}. `
		} else {
			refText += `"${title}" `
			refText += `${parseHarvardDate(publishedDate)} `
			refText += `${sourceInfo}. `
		}
	}

	if (url) {
		refText += `Available at: <a href="${url}" target="_blank" class="text-link">${url}</a>.`
	}

	return refText
}

/**
 * APA Reference Format
 * @param reference
 */
export function parseCiteReferenceInAPAFormat(reference: ReferenceInfo) {
	const { authors, publishedDate, title, url, publisher, page } = reference

	const references = []
	const parsedAuthors = parseAPAReferenceAuthors(authors)

	if (parsedAuthors) {
		references.push(parsedAuthors)

		const date = parseAPADate(publishedDate)
		if (date) {
			references.push(date)
		}

		if (title) {
			references.push(title)
		}
	} else {
		if (title) {
			references.push(title)
		}

		const date = parseAPADate(publishedDate)
		if (date) {
			references.push(date)
		}
	}

	let sourceInfo = ''
	if (publisher) {
		sourceInfo += publisher
	}

	if (page) {
		sourceInfo = `${sourceInfo}, ${page}`
	}

	if (sourceInfo) {
		references.push(sourceInfo)
	}

	if (url) {
		references.push(`<a href="${url}" target="_blank" class="text-link">${url}</a>`)
	}

	return references.join('. ')
}

/**
 * IEEE Reference Format
 * @param reference
 * @param citationIndex
 */
export function parseCiteReferenceInIEEEFormat(reference: ReferenceInfo, citationIndex?: number) {
	const { authors, publishedDate, url, publisher, page, title } = reference

	const references = []
	const parsedAuthors = parseIEEEAuthors(authors)

	if (parsedAuthors) references.push(parsedAuthors)
	if (title) references.push(`"${title}"`)
	if (publisher) references.push(publisher)
	if (page) references.push(`pp. ${page}`)
	if (publishedDate) references.push(parseIEEEDate(publishedDate))
	if (url) references.push(`<a href="${url}" target="_blank" class="text-link">${url}</a>`)

	return `${citationIndex ? `[${citationIndex}] ` : ''}${references.join('. ')}.`
}

/**
 * MLA Reference Format
 * @param reference
 */
export function parseCiteReferenceInMLAFormat(reference: ReferenceInfo) {
	const { authors, publishedDate, title, url, publisher, page } = reference

	let refText = ''
	const parsedAuthors = parseMLAAuthors(authors)

	if (parsedAuthors) {
		refText += `${parsedAuthors}. `
	}

	let sourceInfo = ''
	if (publisher) {
		sourceInfo += publisher
	}

	if (title) {
		if (sourceInfo) {
			refText += `"${title}." `
		} else {
			refText += `${title}. `
		}
	}

	if (sourceInfo) {
		refText += `${sourceInfo}, `
	}

	const date = parseMLADate(publishedDate)
	if (date) {
		refText += `${date}, `
	}

	if (page) {
		refText += `p. ${page}. `
	}

	if (url) {
		refText += `<a href="${url}" target="_blank" class="text-link">${url}</a>. `
	}
	return refText
}

/**
 * Chicago Reference Format
 * @param reference
 */
export function parseCiteReferenceInChicagoFormat(reference: ReferenceInfo) {
	const { authors, publishedDate, title, publisher, page, url } = reference

	let refText = ''
	const parsedAuthors = parseChicagoAuthors(authors)

	if (parsedAuthors) {
		refText += `${parsedAuthors}. `
	}

	if (publishedDate) {
		const year = parseYear(publishedDate)
		if (year) {
			refText += `${year}. `
		}
	}

	if (title) {
		refText += `"${title}" `
	}

	let sourceInfo = ''
	if (publisher) {
		sourceInfo += publisher
	}

	if (page) {
		sourceInfo = `${sourceInfo}: ${page}`
	}

	if (sourceInfo) {
		refText += `${sourceInfo}. `
	}

	if (url) {
		refText += `<a href="${url}" target="_blank" class="text-link">${url}</a>.`
	}

	return refText
}

// Helper Functions

function parseAPAReferenceAuthors(names: string[] | undefined) {
	if (!names || names.length === 0) {
		return ''
	}

	const APA_AUTHORS_MAX_LIMIT = 20
	let parsedNames = ''

	if (names.length > APA_AUTHORS_MAX_LIMIT) {
		for (let i = 0; i < APA_AUTHORS_MAX_LIMIT - 1; i++) {
			const name = names[i]
			parsedNames += `${parseAPARefAuthor(name)}., `
		}

		parsedNames = `${parsedNames}. . . ${parseAPARefAuthor(names[names.length - 1])}`
	} else {
		names.forEach((name, index) => {
			if (name) {
				if (index === names.length - 1) {
					if (parsedNames) {
						parsedNames = `${parsedNames}& ${parseAPARefAuthor(name)}`
					} else {
						parsedNames = parseAPARefAuthor(name)
					}
				} else {
					parsedNames += `${parseAPARefAuthor(name)}., `
				}
			}
		})
	}

	return parsedNames
}

function parseAPARefAuthor(author: string) {
	const name = author.trim()
	if (!name) {
		return ''
	}
	const names = name.split(' ')
	let parsedName = ''
	names.forEach((n, i) => {
		if (i === names.length - 1) {
			if (parsedName) {
				parsedName = `${capitalize(n)},${parsedName}`
			} else {
				parsedName = capitalize(n)
			}
		} else if (n[0]) {
			parsedName += ` ${capitalize(n[0])}`
		}
	})
	return parsedName
}

function parseHarvardAuthors(authors: string[] | undefined) {
	if (!authors || authors.length === 0) {
		return ''
	}
	if (authors.length === 1) {
		return parseHarvardAuthor(authors[0])
	} else if (authors.length < 4) {
		return authors
			.map((author, i) => {
				if (i === 0) {
					return `${parseHarvardAuthor(author)}`
				}
				if (i === authors.length - 1) {
					return ` and ${parseHarvardAuthor(author)}`
				}
				return `, ${parseHarvardAuthor(author)}`
			})
			.join('')
	}
	return `${parseHarvardAuthor(authors[0])} et al.`
}

function parseHarvardAuthor(author: string) {
	const name = author.trim()
	if (!name) {
		return ''
	}

	const _name = name.split(' ').reverse()
	return _name
		.map((n, i) => {
			if (i === 0) {
				return capitalize(n)
			}
			if (n) {
				return n[0]
			}
			return ''
		})
		.join(', ')
}

function parseMLAAuthors(authors: string[] | undefined) {
	if (!authors || authors.length === 0) {
		return ''
	}
	const firstAuthor = authors[0]
	const _name = firstAuthor.split(' ').reverse().join(', ')
	if (authors.length === 1) {
		return _name
	} else if (authors.length === 2) {
		return `${_name}, and ${authors[1]}`
	}
	return `${_name}, et al.`
}

function parseChicagoAuthors(authors: string[] | undefined) {
	if (!authors || authors.length === 0) {
		return ''
	}

	return authors
		.map((author, index) => {
			const name = author.trim()
			if (index === 0) {
				return name.split(' ').reverse().join(', ')
			}

			if (index === authors.length - 1) {
				return 'and ' + name
			}

			return name
		})
		.join(', ')
}

function parseIEEEAuthors(names: string[] | undefined, intext = false) {
	if (!names || names.length === 0) return ''

	if (names.length > (intext ? 3 : 6)) {
		return `${parseIEEEAuthor(names[0])} et al.`
	}

	return names
		.map((name, index) => {
			const author = parseIEEEAuthor(name)
			if (index === 0) {
				return author
			} else if (index === names.length - 1) {
				return ` and ${author}`
			} else {
				return `, ${author}`
			}
		})
		.join('')
}

function parseIEEEAuthor(author: string) {
	const name = author.trim()
	if (!name) {
		return ''
	}
	const names = name.split(' ')
	let parsedName = ''
	names.forEach((n, i) => {
		if (n) {
			if (i === names.length - 1) {
				parsedName += capitalize(n)
			} else {
				parsedName += `${capitalize(n[0])}. `
			}
		}
	})
	return parsedName
}

function parseHarvardDate(date: string | undefined, format = 'YYYY') {
	if (!date) {
		return 'no date'
	}
	const d = new Date(date)
	if (isNaN(d.getTime())) {
		return 'no date'
	}
	if (format === 'YYYY') {
		return d.getFullYear().toString()
	}
	// Add more formatting options if needed
	return d.getFullYear().toString()
}

function parseAPADate(date: string | undefined) {
	if (!date) {
		return '(n.d.)'
	}
	const d = new Date(date)
	if (isNaN(d.getTime())) {
		return '(n.d.)'
	}
	const year = d.getFullYear()
	const monthNames = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	]
	const month = monthNames[d.getMonth()]
	const day = d.getDate()
	return `(${year}, ${month} ${day})`
}

function parseMLADate(date: string | undefined) {
	if (!date) {
		return ''
	}
	const d = new Date(date)
	if (isNaN(d.getTime())) {
		return ''
	}
	const day = d.getDate()
	const monthNamesAbbr = [
		'Jan.',
		'Feb.',
		'Mar.',
		'Apr.',
		'May',
		'June',
		'July',
		'Aug.',
		'Sept.',
		'Oct.',
		'Nov.',
		'Dec.',
	]
	const month = monthNamesAbbr[d.getMonth()]
	const year = d.getFullYear()
	return `${day} ${month} ${year}`
}

function parseIEEEDate(date?: string) {
	if (!date) {
		const now = new Date()
		const day = now.getDate()
		const monthNamesAbbr = [
			'Jan.',
			'Feb.',
			'Mar.',
			'Apr.',
			'May',
			'June',
			'July',
			'Aug.',
			'Sept.',
			'Oct.',
			'Nov.',
			'Dec.',
		]
		const month = monthNamesAbbr[now.getMonth()]
		const year = now.getFullYear()
		return `(Accessed: ${day} ${month} ${year})`
	}
	const d = new Date(date)
	if (isNaN(d.getTime())) {
		return ''
	}
	const monthNamesAbbr = [
		'Jan.',
		'Feb.',
		'Mar.',
		'Apr.',
		'May',
		'June',
		'July',
		'Aug.',
		'Sept.',
		'Oct.',
		'Nov.',
		'Dec.',
	]
	const month = monthNamesAbbr[d.getMonth()]
	const year = d.getFullYear()
	return `${month} ${year}`
}

// Utility function to capitalize a string
function capitalize(word: string) {
	return word.charAt(0).toUpperCase() + word.slice(1)
}

function parseYear(dateString: string | undefined): string | null {
	if (!dateString) {
		return null
	}
	const d = new Date(dateString)
	if (isNaN(d.getTime())) {
		return null
	}
	return d.getFullYear().toString()
}
