import { useAuth } from '../../contexts/Auth'
import Button from '../Buttons'
import { CONSTANTS } from '../../constants'
import { useFeatureFlagVariantKey, usePostHog } from 'posthog-js/react'
import { FEATURE_FLAGS, useFreeWordLimit, usePricing } from '../../hooks/featureFlags'
import useCheckout from '../../hooks/checkout'
import { FaRegCircleCheck } from 'react-icons/fa6'
import UsersImg from '../../assets/images/users.png'
import SaveBadge from '../../assets/images/annualsave.svg'
import { useMemo } from 'react'
import Toggle from '../Toggle'

function Premium({
	closeParentModal,
	setLoginModal,
	annualPlan,
	setAnnualPlan,
	shorten,
	noMoreCredits = false,
}: {
	closeParentModal: () => void
	setLoginModal: (open: boolean) => void
	annualPlan: boolean
	setAnnualPlan: (annual: boolean) => void
	shorten?: boolean
	noMoreCredits?: boolean
}) {
	const upgradeModalV3FF = useFeatureFlagVariantKey(FEATURE_FLAGS.UPGRADE_MODAL_V3)
	const philippinesPricingFF = useFeatureFlagVariantKey(FEATURE_FLAGS.PHILIPPINES_PRICING)
	const paddleFF = useFeatureFlagVariantKey(FEATURE_FLAGS.PADDLE)
	const posthog = usePostHog()
	const { checkout, loading } = useCheckout()
	const pricing = usePricing()
	const { currentUser } = useAuth() as any
	const freeWordLimit = useFreeWordLimit()
	const pathname = window.location.pathname

	function openNextStep() {
		posthog.capture('upgrade-now-clicked')
		if (!currentUser) {
			setLoginModal(true)
			closeParentModal()
		} else {
			checkout(annualPlan)
			if (paddleFF === 'test') {
				closeParentModal()
			}
		}
	}

	const sellingPoints = useMemo(() => {
		let basePoints = [`${CONSTANTS.WORD_COUNT_MAX} Word Essays`, 'Unlimited Essays', 'Advanced Essay Options']
		if (pathname.includes('humanizer')) {
			basePoints.unshift('Unlimited AI Detection')
			basePoints.unshift('Unlimited AI Humanizing')
			return basePoints
		} else if (pathname.includes('detector')) {
			basePoints.unshift('Unlimited AI Humanizing')
			basePoints.unshift('Unlimited AI Detection')
			return basePoints
		}
		basePoints.push('AI Humanizer & Detector')
		return basePoints
	}, [pathname])

	if (upgradeModalV3FF === 'test') {
		return (
			<div className="flex flex-col gap-4 items-start">
				<h6 className="text-2xl text-left">{noMoreCredits ? "You've run out of credits!" : 'Upgrade to Premium'}</h6>
				<p className="text-[18px] text-gray-700 mb-0 text-left">
					<span className="bg-secondary fancy-font text-primary">Supercharge your essay writing</span> with a premium
					plan.
				</p>
				<div
					className={`flex gap-2 items-center justify-center relative mx-auto mt-3 cursor-pointer right-1`}
					onClick={() => setAnnualPlan(!annualPlan)}
				>
					<div className="text-sm font-medium">Monthly</div>
					<Toggle isToggled={annualPlan} onToggle={() => setAnnualPlan(!annualPlan)} />
					<div className="text-sm font-medium">Annual</div>
					{!shorten && <img src={SaveBadge} alt="Save Badge" className="absolute -right-[90px] h-5" />}
				</div>
				<div
					className={`w-full grid md:gap-4 gap-4 grid-cols-1 ${shorten ? 'md:grid-cols-1' : 'md:grid-cols-[1fr,1fr]'}`}
				>
					<div className="border border-black rounded-lg p-4 pb-10">
						<div className="flex gap-1 items-baseline mb-4 mt-1">
							<div className="text-2xl font-bold leading-0 m-0">FREE</div>
						</div>
						<div className="flex flex-col gap-2 mt-4 text-left">
							{[`${freeWordLimit} Word Essays`, 'Basic Essay Options'].map((item, index) => {
								if (!item) return null
								return (
									<div key={index} className="flex gap-3">
										<FaRegCircleCheck className="flex-shrink-0 mt-1" />
										<div>{item}</div>
									</div>
								)
							})}
						</div>
					</div>
					<div className="border border-black rounded-lg p-4 pb-10">
						<div className="flex gap-1 items-baseline">
							<div className="text-3xl font-bold leading-0 m-0">
								{['control', '40'].includes(philippinesPricingFF as string) ? '₱' : '$'}
								{annualPlan ? pricing.yearly : pricing.monthly}
							</div>
							<div className="text-sm relative bottom-0.5">/month</div>
						</div>
						<div className="flex flex-col gap-2 mt-4 text-left">
							{sellingPoints.map((item, index) => {
								if (!item) return null
								return (
									<div key={index} className="flex gap-3">
										<FaRegCircleCheck className="flex-shrink-0 mt-1" />
										<div>{item}</div>
									</div>
								)
							})}
						</div>
					</div>
				</div>
				<Button
					type="default"
					htmlType="submit"
					onClick={openNextStep}
					className={`${shorten ? 'py-0' : 'py-2'} border border-primary font-medium text-lg w-full mt-2`}
				>
					<span>{loading ? 'Loading...' : 'Get started'}</span>
				</Button>
				<div className="flex gap-2 items-center">
					<img src={UsersImg} alt="Users" className="h-12" />
					<div className="flex flex-col gap-0 items-start">
						<p className="m-0 text-sm">Join over 3 million students</p>
						<p className="m-0 text-sm">writing with The Good AI</p>
					</div>
				</div>
			</div>
		)
	}

	return (
		<div className={`flex flex-col ${shorten ? 'gap-3' : !!pricing.yearly ? 'gap-4' : 'gap-6'}`}>
			<h6 className="text-2xl text-left">{noMoreCredits ? "You've run out of credits!" : 'Upgrade to Premium'}</h6>
			<p className="text-[18px] text-gray-700 mb-0 text-left">
				<span className="bg-secondary fancy-font text-primary">Supercharge your essay writing</span> with a premium
				plan.
			</p>
			{!!pricing.yearly && (
				<div className="flex flex-col items-center">
					<div className="rounded-full p-1 flex gap-2 border border-gray-400">
						<div
							className={`cursor-pointer rounded-full py-1 px-3 text-sm font-semibold ${
								!annualPlan ? 'bg-gray-300 text-primary' : 'text-gray-700'
							}`}
							onClick={() => {
								setAnnualPlan(false)
							}}
						>
							Monthly
						</div>
						<div
							className={`cursor-pointer rounded-full py-1 pl-3 pr-2 text-sm font-semibold ${
								annualPlan ? 'bg-gray-300 text-primary' : 'text-gray-700'
							}`}
							onClick={() => {
								setAnnualPlan(true)
							}}
						>
							Annual
							<span className="pl-1 pr-2 py-0.5 bg-success-light rounded-full text-xs border border-success-default text-success-dark ml-2">
								{' '}
								Save {pricing.discount}%
							</span>
						</div>
					</div>
				</div>
			)}
			<div className="flex justify-between flex-wrap gap-4">
				{!shorten && (
					<div className="flex flex-col gap-2">
						<h6 className="text-2xl text-left font-bold mt-1">FREE</h6>
						<div className="flex flex-col gap-2">
							{[`${freeWordLimit} Word Essays`, 'Basic Essay Options'].map((item, index) => {
								if (!item) return null
								return (
									<div key={index} className="flex gap-2 items-center">
										<FaRegCircleCheck className="text-success-default text-xl" />
										<div>{item}</div>
									</div>
								)
							})}
						</div>
					</div>
				)}
				<div className="flex flex-col gap-2">
					<div className="flex flex-col gap-2">
						<div className="flex gap-1 items-baseline">
							<div className="text-3xl font-bold leading-0 m-0">
								{['control', '40'].includes(philippinesPricingFF as string) ? '₱' : '$'}
								{annualPlan ? pricing.yearly : pricing.monthly}
							</div>
							<div className="text-sm relative bottom-0.5">/month</div>
						</div>
					</div>
					<div className="flex flex-col gap-2">
						{sellingPoints.map((item, index) => {
							if (!item) return null
							return (
								<div key={index} className="flex gap-2 items-center">
									<FaRegCircleCheck className="text-success-default text-xl" />
									<div>{item}</div>
								</div>
							)
						})}
					</div>
				</div>
			</div>
			{/* {pricing.yearly && (
				<div className="flex flex-col gap-3">
					<div
						className={`flex justify-between border px-3 py-2 cursor-pointer ${
							annualPlan ? 'border-[#20718B] border-2 bg-gray-100' : 'border-gray-400'
						}`}
						onClick={() => {
							setAnnualPlan(true)
							setAnnualPlan(true)
						}}
					>
						<div className="flex justify-between gap-2 items-center">
							<div className="font-semibold text-sm">Annual</div>
							<div className="border border-success-default bg-success-light rounded-lg text-xs text-success-dark px-1">
								Save {pricing.discount}%
							</div>
						</div>
						<div className="text-sm font-semibold">${pricing.yearly}.00/mo</div>
					</div>
					<div
						className={`flex justify-between border px-3 py-2 cursor-pointer ${
							!annualPlan ? 'border-[#20718B] border-2 bg-gray-100' : 'border-gray-400'
						}`}
						onClick={() => {
							setAnnualPlan(false)
							setAnnualPlan(false)
						}}
					>
						<div className="flex justify-between gap-2 items-center">
							<div className="font-semibold text-sm">Monthly</div>
						</div>
						<div className="text-sm font-semibold">${pricing.monthly}.00/mo</div>
					</div>
				</div>
			)} */}
			<Button
				type="primary"
				htmlType="submit"
				onClick={openNextStep}
				emphasize
				className={`${shorten ? 'py-1' : 'py-2'} border border-primary font-semibold text-xl`}
			>
				<span>{loading ? 'Loading...' : 'Get started'}</span>
			</Button>
			<div className="flex gap-2 items-center">
				<img src={UsersImg} alt="Users" className="h-12" />
				<div className="flex flex-col gap-0 items-start">
					<p className="m-0 text-sm">Join over 3 million students</p>
					<p className="m-0 text-sm">writing with The Good AI</p>
				</div>
			</div>
		</div>
	)
}

export default Premium
