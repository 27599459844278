import './_Input.scss'

const Input = ({
	value,
	onChange,
	onKeyDown,
	id,
	type = 'text',
	placeholder,
}: {
	value: string
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
	onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
	id?: string
	type?: string
	placeholder: string
}) => {
	return (
		<input
			placeholder={placeholder}
			onChange={onChange}
			onKeyDown={onKeyDown}
			value={value}
			type={type}
			id={id}
			className="text-input border border-gray-500 focus:border-gray-700 block w-full p-[8px] outline-none"
		/>
	)
}

export default Input
