import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { contactFormLink } from '../../App'
import { useAuth } from '../../contexts/Auth'
import Button from '../Buttons'
import './_footer.scss'
import { useIsInApp } from '../../helpers/utility'
import useEnterApp from '../../hooks/enterApp'

function Footer(props: RouteComponentProps) {
	const isInApp = useIsInApp()
	const { currentUser } = useAuth() as any
	const { enterApp } = useEnterApp({ history: props.history })

	return (
		<footer>
			<div className="getStarted">
				<h5 className="fancy-font header-md">Get Started For Free.</h5>
				<Button type="secondary" onClick={enterApp} className="bg-white text-xl">
					<span>{isInApp ? (currentUser ? 'ACCOUNT' : 'LOG IN') : 'Use for Free'}</span>
				</Button>
			</div>
			<div className="links">
				<Link to="/" className="_2">
					<p>Home</p>
				</Link>
				<a href={contactFormLink} target="_blank" rel="noreferrer">
					<p className="contactUs">Help Center</p>
				</a>
				<Link to="/examples" className="_2">
					<p>Examples</p>
				</Link>
				<Link to="/blog" className="_2">
					<p>Visit our blog</p>
				</Link>
				<Link to="/post/privacy-policy" className="_2">
					<p>Privacy policy</p>
				</Link>
				<Link to="/post/terms-of-service" className="_2">
					<p>Terms of service</p>
				</Link>
				<a href="https://clarify.ai" target="_blank" rel="noreferrer">
					<p>AI Humanizer</p>
				</a>
				<Link to="/pricing" className="_2">
					<p>Pricing</p>
				</Link>
			</div>
		</footer>
	)
}

export default withRouter(Footer)
