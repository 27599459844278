import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectUser } from '../redux/systemSlice'
import { DOC_TEMPLATES } from '../constants'
import { useCookieUser } from './cookieUser'
import {
	selectDocumentState,
	setIsTypingAddMore,
	setIsTypingEssay,
	streamNewContent,
	streamAddMoreContent,
} from '../redux/docSlice'
import { DocumentState } from '../redux/types'

const useStream = ({
	topicValue,
	template,
	docID,
	addMoreSliderValue,
}: {
	topicValue?: string
	template?: keyof typeof DOC_TEMPLATES
	docID?: string
	addMoreSliderValue?: number
}) => {
	const dispatch = useDispatch()
	const { userIDCookie } = useCookieUser()
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docID ?? ''))
	const user = useSelector(selectUser)
	// Ref to manage the WebSocket connection
	const ws = useRef<WebSocket | null>(null)
	// Maximum number of attempts to reconnect
	const [reconnectAttempts, setReconnectAttempts] = useState(0)
	const maxReconnectAttempts = 5

	// Function to setup the WebSocket connection and define event handlers
	const setupWebSocket = () => {
		ws.current = new WebSocket(
			`wss://the-good-ai-backend-bf629764fcc8.herokuapp.com/ws/${
				addMoreSliderValue ? 'stream_add_more' : 'stream_content'
			}/`
		)

		ws.current.onopen = () => {
			console.log('WebSocket connected!')
			setReconnectAttempts(0) // Reset reconnect attempts on successful connection
		}

		ws.current.onmessage = (event) => {
			const data = JSON.parse(event.data)
			if (addMoreSliderValue) {
				dispatch(streamAddMoreContent({ docID: docID, content: data.message }))
			} else {
				dispatch(streamNewContent({ docID: docID, content: data.message }))
			}
			if (data.message === null) {
				console.log('data.message is null')
				dispatch(setIsTypingEssay({ docID: docID, isTypingEssay: false }))
				dispatch(setIsTypingAddMore({ docID: docID, isTypingAddMore: false }))
			}
		}

		ws.current.onerror = (event) => {
			console.error('WebSocket error observed:', event)
		}
		ws.current.onclose = (event) => {
			console.log(`WebSocket is closed now. Code: ${event.code}, Reason: ${event.reason}`)
			handleReconnect()
		}
	}

	// Function to handle reconnection attempts with exponential backoff
	const handleReconnect = () => {
		if (reconnectAttempts < maxReconnectAttempts) {
			let timeout = Math.pow(2, reconnectAttempts) * 1000 // Exponential backoff
			setTimeout(() => {
				setupWebSocket() // Attempt to reconnect
			}, timeout)
		} else {
			console.log('Max reconnect attempts reached, not attempting further reconnects.')
		}
	}

	// Effect hook to setup and cleanup the WebSocket connection
	useEffect(() => {
		setupWebSocket()

		return () => {
			if (ws?.current?.readyState === WebSocket.OPEN) {
				ws.current.close() // Close WebSocket on component unmount
			}
		}
	}, [])

	// Handler for form submission
	const streamShortEssayContent = () => {
		dispatch(setIsTypingEssay({ docID: docID, isTypingEssay: true }))
		const requestBody = JSON.stringify({
			prompt: topicValue,
			template: template,
			userId: user.id ?? userIDCookie,
			docId: docID,
			userWriterSelections: documentState.userWriterSelections,
		})
		ws?.current?.send(requestBody)
	}

	const _streamAddMoreContent = (prompt: string) => {
		dispatch(setIsTypingAddMore({ docID: docID, isTypingAddMore: true }))

		const requestBody = JSON.stringify({
			prompt: prompt, // last 100 words
			userId: user.id ?? userIDCookie,
			docId: docID,
			wordCount: addMoreSliderValue,
		})
		ws?.current?.send(requestBody)
	}

	return { streamShortEssayContent, streamAddMoreContent: _streamAddMoreContent }
}

export function useStreamingText(text: string, active: boolean, onEnd?: () => void) {
	const [displayedText, setDisplayedText] = useState('')
	const words = text.split(' ')

	useEffect(() => {
		if (!active) return
		setDisplayedText('')
		let wordIndex = 0
		const intervalId = setInterval(() => {
			setDisplayedText((prev) => prev + (prev ? ' ' : '') + words.slice(wordIndex, wordIndex + 2).join(' '))
			wordIndex += 2

			if (wordIndex >= words.length) {
				onEnd?.()
				clearInterval(intervalId)
			}
		}, 50)

		return () => clearInterval(intervalId)
	}, [text, active])

	return displayedText
}

export default useStream
