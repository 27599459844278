import { initializeApp, getApps } from 'firebase/app'
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth'

getApps().length === 0 &&
	initializeApp({
		apiKey: 'AIzaSyCkNRDrDlnmAq5z1TT1Qfpem_b1eJHWcTc',
		authDomain: 'production-c058e.firebaseapp.com',
		projectId: 'production-c058e',
		storageBucket: 'production-c058e.appspot.com',
		messagingSenderId: '108231102386',
		appId: '1:108231102386:web:4ddf4485ca6f75e9689eab',
	})

export const auth = getAuth()

// Initialize Firebase Auth provider
const provider = new GoogleAuthProvider()

provider.addScope('email')

// whenever a user interacts with the provider, we force them to select an account
provider.setCustomParameters({
	prompt: 'select_account ',
})
export const signInWithGooglePopup = () => signInWithPopup(auth, provider)
