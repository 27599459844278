import { useSelector } from 'react-redux'
import { DocumentState } from '../redux/types'
import { selectDocumentState } from '../redux/docSlice'
import { useDocId } from './docID'
import { useMemo } from 'react'

const useNumSubtopics = () => {
	const docID = useDocId()
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docID))

	const maxNumberOfSubtopics = useMemo(() => {
		return Math.floor((documentState.userWriterSelections.wordCount - 500) / 250)
	}, [documentState.userWriterSelections.wordCount])

	const minNumberOfSubtopics = useMemo(() => {
		return Math.ceil((documentState.userWriterSelections.wordCount - 500) / 600)
	}, [documentState.userWriterSelections.wordCount])

	const averageNumberOfSubtopics = useMemo(() => {
		return Math.floor((maxNumberOfSubtopics + minNumberOfSubtopics) / 2)
	}, [maxNumberOfSubtopics, minNumberOfSubtopics])

	return { maxNumberOfSubtopics, minNumberOfSubtopics, averageNumberOfSubtopics }
}

export default useNumSubtopics
