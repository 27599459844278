import React, { RefObject } from 'react'

type buttonProps = {
	children: React.ReactNode
	type: 'primary' | 'secondary' | 'tertiary' | 'default' | 'ghost' | 'outline'
	emphasize?: boolean
	onClick?: (e?: any) => void
	onMouseDown?: (e?: any) => void
	disabled?: boolean
	className?: string
	htmlType?: 'button' | 'submit' | 'reset' | undefined
	style?: React.CSSProperties
	btnRef?: RefObject<HTMLButtonElement>
}

const Button = (props: buttonProps) => {
	const buttonTypeClassNames = {
		primary: '',
		secondary: 'uppercase py-1 px-3 hover:bg-secondary fancy-font',
		tertiary: 'border border-primary px-5 py-0.5 uppercase',
		default: 'rounded-lg bg-primary text-white px-3 py-2 font-semibold',
		ghost: 'rounded-lg hover:bg-gray-200 px-3 py-2 font-semibold',
		outline: 'rounded-lg hover:bg-gray-200 px-3 py-2 font-semibold border border-gray-400',
	}
	const buttonClassNames = buttonTypeClassNames[props.type]
	const button = (
		<button
			ref={props.btnRef}
			onClick={() => {
				if (props.onClick && !props.disabled) {
					props.onClick()
				}
			}}
			onMouseDown={() => {
				if (props.onMouseDown && !props.disabled) {
					props.onMouseDown()
				}
			}}
			className={`
				cursor-pointer 
				transition-colors
				${buttonClassNames} ${props.disabled ? 'cursor-default opacity-60 pointer-events-none' : ''} ${
				props.className ? props.className : ''
			} ${
				props.emphasize
					? 'bg-secondary border border-primary border-solid hover:!bg-[#a1edeb]'
					: props.type === 'tertiary'
					? 'border-dotted hover:bg-secondary'
					: ''
			}
				`}
			type={props.htmlType ?? 'button'}
			style={props.style}
		>
			{props.children}
		</button>
	)
	return button
}

export default Button
