import { useState } from 'react'
import Button from '../Buttons'
import CustomSourceModal from '../Modals/CustomSource'
import { DocumentState, ReferencesInfo } from '../../redux/types'
import { useDispatch, useSelector } from 'react-redux'
import { selectDocumentState, setReferences } from '../../redux/docSlice'
import { useDocId } from '../../hooks/docID'
import { FaCheck } from 'react-icons/fa6'
import { FaRegTrashAlt } from 'react-icons/fa'
import { useAuth } from '../../contexts/Auth'

const SourceUpload = () => {
	const dispatch = useDispatch()
	const docId = useDocId()
	const [modalOpen, setModalOpen] = useState(false)
	const { currentUser } = useAuth() as any
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docId))

	const customSource = documentState?.references?.find((ref) => ref.custom)

	const removeReference = async () => {
		let newReferences = documentState.references.filter((ref) => !ref.custom)

		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				authorization: currentUser ? `Bearer ${await currentUser.getIdToken()}` : '',
			},
			body: JSON.stringify({
				id: docId,
				references: newReferences,
			}),
		}
		fetch(process.env.REACT_APP_API_ROOT + '/document/update/', requestOptions)
			.then((res) => res.json())
			.then((result: { references: ReferencesInfo[] }) => {
				dispatch(setReferences({ docID: docId, references: result.references }))
			})
			.catch((err) => {
				console.error('Error creating source:', err)
			})
	}

	return (
		<>
			<CustomSourceModal isOpen={modalOpen} onClose={() => setModalOpen(false)} />
			<div className="flex gap-2 mt-10 justify-between w-full">
				<div className="text-xl font-semibold whitespace-nowrap mt-1">Custom source</div>
				<div className="flex gap-3 items-center flex-wrap-reverse justify-end">
					{customSource && (
						<span className="text-sm text-success-dark whitespace-nowrap">
							<FaCheck className="inline-block mb-0.5 mr-1" /> Source added
						</span>
					)}
					<Button
						type="primary"
						className="border border-gray-700 hover:bg-secondary py-1 px-3 text-lg font-medium"
						onClick={() => {
							customSource ? removeReference() : setModalOpen(true)
						}}
					>
						{customSource ? (
							<div className="flex gap-2 items-center">
								<FaRegTrashAlt size={13} />
								Remove
							</div>
						) : (
							'Add file or text'
						)}
					</Button>
				</div>
			</div>
		</>
	)
}

export default SourceUpload
