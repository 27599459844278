import './humanizer.scss'
import Paragraph from '@tiptap/extension-paragraph'
import Document from '@tiptap/extension-document'
import Placeholder from '@tiptap/extension-placeholder'
import Text from '@tiptap/extension-text'
import { useAtom } from 'jotai'
import { useEffect } from 'react'
import { editorAtom, editorDefaultValues } from './constants'
import { useWordCount } from './hooks'
import { useEditor } from '@tiptap/react'
import { useHistory, useLocation } from 'react-router-dom'
import Button from '../../Components/Buttons'
import CheckForAi from './check-for-ai'

export function DetectorEditor() {
	const [editorState, setEditorState] = useAtom(editorAtom)
	const wordCount = useWordCount(editorState.textInputValue)
	const location = useLocation()
	const history = useHistory()

	useEffect(() => {
		setEditorState({
			...editorState,
			aiDetectionScore: undefined,
			previousHumanization: undefined,
		})
	}, [editorState.textInputValue])

	const editor = useEditor(
		{
			extensions: [
				Document,
				Text,
				Paragraph,
				Placeholder.configure({
					placeholder: 'Paste your text here...',
				}),
			],
			onUpdate: ({ editor }) => {
				setEditorState({ ...editorState, textInputValue: editor.getText() })
			},
		},
		[]
	)

	useEffect(() => {
		if (!editor) return
		if (editorState.textInputValue !== editor?.getText()) {
			editor.commands.setContent(editorState.textInputValue, false, {
				preserveWhitespace: 'full',
			})
		}
	}, [editorState.textInputValue, editor])

	useEffect(() => {
		setEditorState(editorDefaultValues)
		editor?.commands.setContent(editorDefaultValues.textInputValue, false, {
			preserveWhitespace: 'full',
		})
	}, [location.pathname])

	return (
		<div className="flex flex-col gap-4  w-full min-h-[500px] shadow-lg border rounded-xl border-gray-400 p-4 bg-white items-start">
			<div className="grid grid-cols-2 md:grid-cols-4 gap-2 overflow-x-auto md:w-auto w-full">
				{[
					{
						name: 'ChatGPT',
						text: "Global warming is a pressing environmental issue characterized by the increase in Earth's average temperature due to the release of greenhouse gases from human activities such as fossil fuel use, deforestation, and industrial processes. This phenomenon has far-reaching consequences, including rising sea levels, extreme weather events, and disruptions to ecosystems and biodiversity. The urgency of addressing global warming requires concerted efforts from individuals, governments, and industries to reduce carbon emissions, invest in renewable energy sources, and implement sustainable practices to mitigate the impacts of a changing climate on our planet.",
					},
					{
						name: 'GPT-4o',
						text: "Global warming refers to the long-term increase in Earth's average surface temperature due to human activities, primarily the emission of greenhouse gases such as carbon dioxide, methane, and nitrous oxide. These emissions result from the burning of fossil fuels, deforestation, industrial processes, and agricultural practices, which trap heat in the atmosphere and lead to the greenhouse effect. This warming trend has profound implications for the planet, including the melting of polar ice caps, rising sea levels, more frequent and severe weather events, shifts in ecosystems and wildlife distributions, and challenges for agriculture and water resources. Addressing global warming requires global cooperation and the implementation of sustainable practices, renewable energy sources, and policy measures aimed at reducing greenhouse gas emissions and adapting to inevitable climate changes.",
					},
					{
						name: 'Human',
						text: "Global warming occurs when CO2 and other pollutants have accumulate in the atmosphere and capture sunlight and solar radiation that reflect off Earth's surface. Normally this radiation would disappear into space. However these pollutants which persist for years to centuries in the atmosphere, capture heat, resulting in warmer planet. These heat-retaining pollutants—such as carbon dioxide, methane, nitrous oxide, water vapor, and synthetic gases—are referred to as greenhouse gases. Their effect was termed the 'greenhouse effect.",
					},
					{
						name: 'Human + AI',
						text: 'Global warming happens when carbon dioxide (CO2) and other air pollutants accumulate in the atmosphere, absorbing sunlight and solar radiation that reflect off the earth’s surface. Under normal circumstances, this radiation would escape into space, but these pollutants, which can remain in the atmosphere for years or even centuries, trap heat and lead to an increase in the planet’s temperature. These heat-trapping pollutants—such as carbon dioxide, methane, nitrous oxide, water vapor, and synthetic fluorinated gases—are known as greenhouse gases, and their collective impact is referred to as the greenhouse effect.',
					},
				].map((source, index) => (
					<Button
						key={index}
						type="ghost"
						className="border border-gray-400"
						onClick={() => {
							setEditorState({ ...editorState, textInputValue: source.text })
						}}
					>
						{source.name}
					</Button>
				))}
			</div>
			<textarea
				placeholder="Type your text here..."
				className="w-full resize-none outline-none p-4 grow overflow-y-auto rounded-xl leading-relaxed border border-gray-400"
				onChange={(e) => {
					setEditorState({ ...editorState, textInputValue: e.target.value })
				}}
				value={editorState.textInputValue}
			/>
			<div className="flex justify-between w-full items-center">
				<CheckForAi
					text={editorState.textInputValue}
					emphasizeBtn
					onHumanizeClick={() => {
						history.push('/humanizer')
					}}
					context="detector"
				/>
				<div className="font-semibold">{wordCount}/2500 Words</div>
			</div>
		</div>
	)
}
