import useIsPremium from '../hooks/useIsPremium'
import useHumanizerCredits from '../hooks/useHumanizerCredits'
import { useCookies } from 'react-cookie'
import { useFeatureFlagVariantKey } from 'posthog-js/react'
import { FEATURE_FLAGS } from '../hooks/featureFlags'

const ToolCredits = () => {
	const updatedHumanizerDetectorUIFF = useFeatureFlagVariantKey(FEATURE_FLAGS.UPDATED_HUMANIZER_DETECTOR_UI)
	const [cookies] = useCookies(['humanizerCredits'])
	const { creditsAvailable } = useHumanizerCredits()
	const isPremium = useIsPremium()
	const pathname = window.location.pathname

	if (isPremium || pathname.includes('detector')) {
		return null
	}

	return (
		<div className="flex gap-1">
			<div className="text-gray-600">Free credits: </div>
			<div className="font-semibold">
				{updatedHumanizerDetectorUIFF === 'test'
					? creditsAvailable
					: Math.max(0, 300 - (cookies.humanizerCredits ?? 0))}{' '}
				words
			</div>
		</div>
	)
}

export default ToolCredits
