import { useFeatureFlagVariantKey } from 'posthog-js/react'
import { FEATURE_FLAGS } from '../hooks/featureFlags'
import { DetectorEditor } from './Humanizer/detector-editor'
import Editor from './Humanizer/editor'

const DetectorPage = () => {
	const updatedHumanizerDetectorUIFF = useFeatureFlagVariantKey(FEATURE_FLAGS.UPDATED_HUMANIZER_DETECTOR_UI)

	return (
		<div className="flex flex-col gap-5 mt-20">
			<div className="flex flex-col">
				<div className="text-3xl font-semibold text-center">AI Detector</div>
				<div className="text-center text-gray-700">
					Scan for AI using {updatedHumanizerDetectorUIFF === 'test' ? '6' : '8'} different detectors.
				</div>
			</div>
			<div
				className={
					updatedHumanizerDetectorUIFF === 'test'
						? 'z-10 max-w-6xl mx-auto w-full pb-96 md:pb-24'
						: 'flex justify-center z-10'
				}
			>
				{updatedHumanizerDetectorUIFF === 'test' ? <DetectorEditor /> : <Editor detector />}
			</div>
			<div className="w-screen h-screen fixed bg-gradient-to-b from-white to-[#3b82f6] top-0 opacity-15" />
		</div>
	)
}

export default DetectorPage
