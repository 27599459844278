import { selectDocumentState, setReferences } from '../../redux/docSlice'
import { DocumentState } from '../../redux/types'
import { useDocId } from '../../hooks/docID'
import { useDispatch, useSelector } from 'react-redux'
import ReferencesDropdown from '../WriterOptions/ReferencesDropdown'
import { FaRegTrashAlt } from 'react-icons/fa'
import { useAuth } from '../../contexts/Auth'
import { useFeatureFlagVariantKey } from 'posthog-js/react'
import { FEATURE_FLAGS } from '../../hooks/featureFlags'
import { formatReference, getReferenceText } from '../../helpers/strings'

const References = () => {
	const docId = useDocId()
	const dispatch = useDispatch()
	const { currentUser } = useAuth() as any
	const citationJsFF = useFeatureFlagVariantKey(FEATURE_FLAGS.CITATION_JS_REFERENCES)
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docId))

	const removeReference = async (index: number) => {
		let newReferences = [...(documentState?.references ?? [])]
		newReferences.splice(index, 1)
		dispatch(
			setReferences({
				docID: docId,
				references: newReferences,
			})
		)
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				authorization: currentUser ? `Bearer ${await currentUser.getIdToken()}` : '',
			},
			body: JSON.stringify({
				id: docId,
				references: newReferences,
			}),
		}
		fetch(process['env']['REACT_APP_API_ROOT'] + '/document/update/', requestOptions)
	}

	if (
		!documentState ||
		documentState.isGenerating ||
		!documentState.setup ||
		!documentState.userWriterSelections.refStyle ||
		documentState.references.length === 0
	) {
		return null
	}

	return (
		<div className="flex flex-col gap-3 mt-8">
			<div className="flex justify-between items-center">
				<div className="flex gap-4 items-center">
					<div className="text-2xl font-semibold">References</div>
				</div>
				<ReferencesDropdown docID={docId} includeNull={false} />
			</div>
			<div className="flex flex-col gap-6">
				{documentState.references.map((ref, index) => (
					<div key={index} className="grid grid-cols-[1fr,0] items-center overflow-hidden sm:overflow-visible">
						{citationJsFF === 'test' ? (
							<div
								className="text-lg leading-loose primary-font break-all"
								dangerouslySetInnerHTML={
									citationJsFF === 'test'
										? {
												__html: getReferenceText(ref, index, documentState.userWriterSelections.refStyle!),
										  }
										: undefined
								}
							/>
						) : (
							<div className="text-lg leading-loose primary-font break-all">
								{formatReference(documentState.userWriterSelections.refStyle!, ref, index)}
							</div>
						)}
						<div
							className="cursor-pointer relative md:left-8 group sm:block w-10"
							onClick={() => removeReference(index)}
						>
							<FaRegTrashAlt className="cursor-pointer text-gray-500 group-hover:text-primary" />
						</div>
					</div>
				))}
			</div>
		</div>
	)
}

export default References
