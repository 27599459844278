const Toggle = ({ onToggle, isToggled }: { onToggle: () => void; isToggled: boolean }) => {
	return (
		<div
			className={`flex cursor-pointer transition-colors justify-center p-0.5 w-8 border border-primary rounded-full shadow ${
				isToggled ? 'bg-primary' : 'bg-white'
			}`}
			onClick={onToggle}
		>
			<div
				className={`w-3 h-3 transition-all rounded-full relative ${
					isToggled ? 'left-[6px] bg-white' : 'left-[-6px] bg-primary'
				}`}
			/>
		</div>
	)
}

export default Toggle
