import { useState } from 'react'
import { useDocId } from '../../hooks/docID'
import Modal from './Modal'
import useLoadingDots from '../../hooks/loadingDots'
import Button from '../Buttons'
import './_CustomSource.scss'
import Input from '../Input'
import { LuUpload } from 'react-icons/lu'
import { TextareaAutosize } from '@mui/material'
import { selectDocumentState, setReferences } from '../../redux/docSlice'
import { DocumentState, ReferencesInfo } from '../../redux/types'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth } from '../../contexts/Auth'
import { usePostHog } from 'posthog-js/react'

const CustomSourceModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
	const docId = useDocId()
	const [docUploading, setDocUploading] = useState(false)
	const [sourceLoading, setSourceLoading] = useState(false)
	const [title, setTitle] = useState<string>('')
	const [authors, setAuthors] = useState<string>('')
	const [year, setYear] = useState<string>('')
	const [url, setUrl] = useState<string>('')
	const [sourceContent, setSourceContent] = useState<string | undefined>(undefined)
	const [submitAttempted, setSubmitAttempted] = useState<boolean>(false)
	const [mode, setMode] = useState<'upload' | 'manual'>('upload')
	const [uploadedFileName, setUploadedFileName] = useState<string | undefined>(undefined)
	const documentState: DocumentState = useSelector((state) => selectDocumentState(state, docId))
	const dispatch = useDispatch()
	const posthog = usePostHog()
	const { currentUser } = useAuth() as any

	const loadingDots = useLoadingDots(docUploading || sourceLoading)

	const uploadFile = async (fileToUpload: File) => {
		setUploadedFileName(fileToUpload.name)
		const formData = new FormData()
		formData.append('file', fileToUpload)
		formData.append('doc_id', docId)

		try {
			setDocUploading(true)
			setSubmitAttempted(false)
			fetch(process.env.REACT_APP_API_ROOT + '/document/file-upload/', {
				method: 'POST',
				body: formData,
			})
				.then((res) => res.json())
				.then((response: { content: string }) => {
					setDocUploading(false)
					setSourceContent(response.content)
				})
		} catch (error) {
			console.error('Error uploading file:', error)
			setMode('manual')
		}
	}

	const createSource = async () => {
		setSourceLoading(true)
		const newReferences = [
			...(documentState.references.filter((ref) => !ref.custom) ?? []),
			{
				title: title,
				url: url,
				publishedDate: year,
				authors: authors
					.split(',')
					.map((author) => author.trim())
					.filter((author) => author.length > 0),
				content: sourceContent,
				custom: true,
			},
		]
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				authorization: currentUser ? `Bearer ${await currentUser.getIdToken()}` : '',
			},
			body: JSON.stringify({
				id: docId,
				references: newReferences,
			}),
		}
		fetch(process.env.REACT_APP_API_ROOT + '/document/update/', requestOptions)
			.then((res) => res.json())
			.then((result: { references: ReferencesInfo[] }) => {
				posthog.capture('custom-source-created')
				setSourceLoading(false)
				dispatch(setReferences({ docID: docId, references: result.references }))
				onClose()
			})
			.catch((err) => {
				console.error('Error creating source:', err)
			})
	}
	const invalidSourceContent = sourceContent === undefined || sourceContent.trim().split(' ').length < 20

	return (
		<Modal open={isOpen} closeModal={onClose} portalClassName="customSource">
			<div className="flex flex-col gap-7">
				<div className="text-xl font-semibold text-center">New custom source</div>
				<div className="grid md:grid-cols-2 gap-3">
					<div className="flex flex-col gap-1">
						<div className="font-semibold">Title of work</div>
						<Input value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Title of work" />
					</div>
					<div className="flex flex-col gap-1">
						<div className="font-semibold">Author(s)</div>
						<Input value={authors} onChange={(e) => setAuthors(e.target.value)} placeholder="John Doe, Jane Smith" />
					</div>
					<div className="flex flex-col gap-1">
						<div className="font-semibold">Year published</div>
						<Input value={year} onChange={(e) => setYear(e.target.value)} placeholder="2024" />
					</div>
					<div className="flex flex-col gap-1">
						<div className="font-semibold">Reference URL</div>
						<Input value={url} onChange={(e) => setUrl(e.target.value)} placeholder="https://example.com" />
					</div>
				</div>
				{mode === 'upload' ? (
					<div className="flex flex-col gap-3 items-center">
						<div
							className={`${
								uploadedFileName ? 'border-success-dark' : 'border-gray-600'
							} h-24 w-full flex justify-center items-center border cursor-pointer relative group hover:bg-gray-200 bg-gray-100 transition-colors rounded`}
						>
							<input
								type="file"
								id="file"
								name="file"
								accept="application/pdf"
								className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
								onChange={(e) => {
									// @ts-ignore
									if (e.target.files[0].size > 2097152) {
										alert('File size must be less than 2MB')
										return
									}
									if (e.target.files) {
										uploadFile(e.target.files[0])
									}
								}}
								disabled={docUploading}
							/>
							<label htmlFor="file" className="text-gray-800 text-center font-semibold">
								{docUploading ? (
									<div>Uploading{loadingDots}</div>
								) : uploadedFileName ? (
									<div className="text-success-default text-sm">{uploadedFileName}</div>
								) : (
									<div className="flex gap-2 items-center justify-center">
										<LuUpload />
										Upload PDF
									</div>
								)}
							</label>
						</div>
						{submitAttempted && invalidSourceContent && (
							<div className="text-danger-default text-sm text-left w-full">
								Please upload a PDF file with at least 20 words.
							</div>
						)}
						<div className="text-sm text-gray-700 font-semibold">OR</div>
						<Button
							type="primary"
							className="text-link font-semibold"
							onClick={() => {
								setMode('manual')
								setSourceContent('')
								setUploadedFileName(undefined)
							}}
						>
							Enter text manually
						</Button>
					</div>
				) : (
					<div className="flex flex-col gap-3 items-center">
						<TextareaAutosize
							className="border border-gray-600 rounded w-full p-2 overflow-auto outline-none resize-none"
							placeholder="Paste text here..."
							minRows={4}
							maxRows={10}
							autoFocus
							value={sourceContent}
							onChange={(e) => {
								setSourceContent(e.target.value)
								setSubmitAttempted(false)
							}}
						/>
						{submitAttempted && invalidSourceContent && (
							<div className="text-danger-default text-sm text-left w-full">Please enter at least 20 words.</div>
						)}
						<div className="text-sm text-gray-700 font-semibold">OR</div>
						<Button
							type="primary"
							className="text-link font-semibold"
							onClick={() => {
								setMode('upload')
								setSourceContent('')
								setUploadedFileName(undefined)
							}}
						>
							Upload PDF
						</Button>
					</div>
				)}
				<Button
					type="secondary"
					onClick={() => {
						setSubmitAttempted(true)
						if (invalidSourceContent) {
							return
						}
						createSource()
					}}
					disabled={docUploading}
					emphasize
					className="py-2"
				>
					{sourceLoading ? `Creating ${loadingDots}` : 'Create source'}
				</Button>
			</div>
		</Modal>
	)
}

export default CustomSourceModal
