import { useFeatureFlagVariantKey } from 'posthog-js/react'
import { useLocalStorage } from '../helpers/utility'

export const FEATURE_FLAGS = {
	WORD_COUNT: 'word-count-2',
	PHILIPPINES_PRICING: 'philippines-pricing',
	STEALTH_GPT: 'stealth-gpt',
	PADDLE: 'paddle',
	GRADER_V2: 'grader-v2',
	ASK_AI_PROMPT: 'ask-ai-prompt',
	MONTHLY_SUGGESTION: 'monthly-suggestion',
	CRISP_CHATBOX: 'crisp-chatbox',
	SIMPLIFY_TOPIC: 'simplify-topic',
	STEPPED_ESSAY_SETUP: 'stepped-essay-setup',
	CUSTOM_SOURCE: 'custom-source',
	CITATION_JS_REFERENCES: 'citation-js-references',
	EXCLUDE_TAX: 'exclude-tax',
	UPDATED_HUMANIZER_DETECTOR_UI: 'updated-humanizer-detector-ui',
	WRITING_STYLE_V2: 'writing-style-v2',
	REPLACE_ARROWS: 'replace-arrows',
	CHECK_FOR_AI_V2: 'check-for-ai-v2',
	UPGRADE_MODAL_V2: 'upgrade-modal-v2',
	UPGRADE_MODAL_V3: 'upgrade-modal-v3',
	AUTO_EXA: 'auto-exa',
	HUMANIZER_V2: 'humanizer-v2',
	CLOSE_CUSTOM_STYLE_MODAL: 'close-custom-style-modal',
	DETECTION_HIGHLIGHTING: 'detection-highlighting',
}

export const usePricing = () => {
	const philippinesPricingFF = useFeatureFlagVariantKey(FEATURE_FLAGS.PHILIPPINES_PRICING) as 'control' | '40'
	const excludeTaxFF = useFeatureFlagVariantKey(FEATURE_FLAGS.EXCLUDE_TAX)

	const defaultPricing = {
		monthly: '9',
		yearly: '6',
		yearlyTotal: '72',
		discount: '33',
		stripePriceId: {
			yearly: excludeTaxFF === 'test' ? 'price_1QPeEMApflHFLqu6MRU91OwT' : 'price_1Q39I6ApflHFLqu6T9HPheD3',
			monthly: excludeTaxFF === 'test' ? 'price_1QPYsOApflHFLqu6Zwlql3t0' : 'price_1PsqLqApflHFLqu6N1DpjqlU',
		},
	}
	const philippinesPricing = {
		monthly: {
			control: '400',
			'40': '300',
		},
		yearly: {
			control: '292',
			'40': '233',
		},
		yearlyTotal: {
			control: '3500',
			'40': '2800',
		},
		discount: {
			control: '27',
			'40': '22',
		},
		stripePriceId: {
			yearly: {
				control: 'price_1Pyd4cApflHFLqu6LnNTg4ST',
				'40': 'price_1Pyd4FApflHFLqu6rCWfWwRx',
			},
			monthly: {
				control: 'price_1Pyd2OApflHFLqu6dCeBSFOI',
				'40': 'price_1Pyd15ApflHFLqu6ekx5wHMk',
			},
		},
	}

	if (['control', '40'].includes(philippinesPricingFF as string)) {
		return {
			monthly: philippinesPricing.monthly[philippinesPricingFF] as string,
			yearly: philippinesPricing.yearly[philippinesPricingFF] as string | null,
			yearlyTotal: philippinesPricing.yearlyTotal[philippinesPricingFF] as string | null,
			discount: philippinesPricing.discount[philippinesPricingFF] as string | null,
			stripePriceId: {
				yearly: philippinesPricing.stripePriceId.yearly[philippinesPricingFF] as string | null,
				monthly: philippinesPricing.stripePriceId.monthly[philippinesPricingFF] as string,
			},
		}
	}

	return defaultPricing
}

export const useFreeWordLimit = () => {
	const variant = useFeatureFlagVariantKey(FEATURE_FLAGS.WORD_COUNT)

	const variantToLimit = {
		control: 250,
		'150': 150,
		'350': 350,
	}

	// @ts-ignore
	return variantToLimit[variant ?? 'control'] as 150 | 250 | 350
}

export const useAnnualPlan = () => {
	const [annualPlan, setAnnualPlan] = useLocalStorage('annualPlan', false)

	return [annualPlan, setAnnualPlan] as const
}
