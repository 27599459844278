import {
	CONSTANTS,
	HUMANIZER_OPTIONS,
	NULL_USER,
	READING_LEVEL_OPTIONS,
	TONE_OPTIONS,
	TYPE_OPTIONS,
} from '../constants'
import { DocumentState, Documents, REF_STYLES, SystemState, UserWriterSelections } from './types'

export const blankUserWriterSelections: UserWriterSelections = {
	wordCount: 250,
	refStyle: 'APA_7' as REF_STYLES,
	tone: TONE_OPTIONS[0],
	type: TYPE_OPTIONS[0],
	readingLevel: READING_LEVEL_OPTIONS[READING_LEVEL_OPTIONS.length - 2],
	humanizerIntensity: HUMANIZER_OPTIONS[0],
	subTopics: [],
	thesisStatement: {
		text: '',
		active: false,
	},
	writingStyle: {
		generalSelected: true,
	},
}

export const newDocument: DocumentState = {
	setup: true,
	setupChanged: false,
	content: CONSTANTS.EMPTY_DOCUMENT,
	drafts: [],
	jsonContent: {},
	title: '',
	lastSavedContent: '',
	lastSavedTitle: '',
	userWriterSelections: blankUserWriterSelections,
	isGenerating: false,
	isGeneratingSubtopics: false,
	isHumanizing: false,
	needsHumanizing: false,
	isTypingAddMore: false,
	isTypingEssay: false,
	isOnSubtopicsStep: false,
	references: [],
}

export const initialDocumentsState: Documents = {
	'': newDocument, //If urls param id is undefined, then docID prop will be ''
}

export const initialSystemState: SystemState = {
	showNav: true,
	lastAggregatePageVisited: '/blog',
	user: NULL_USER,
	userLoading: true,
	userPagesCache: { source: 'cookies', pages: [], dirty: false },
	userPagesLoading: false,
	tiptapJWToken: undefined,
	graderModeOn: false,
	selectedGraderCategory: 'grammar',
	categoryScoresLoading: false,
	numAcceptedSuggestions: 0,
}
