import './humanizer.scss'
import { useAtom } from 'jotai'
import { useEffect, useMemo, useState } from 'react'
import EditorTopSection from './top-section'
import { MAX_HUMANIZER_WORD_COUNT, editorAtom } from './constants'
import { useHumanizeText, useWordCount } from './hooks'
import UpgradeModals from '../../Components/Modals/Upgrade'
import useIsPremium from '../../hooks/useIsPremium'
import { Bounce, toast } from 'react-toastify'
import CopyToClipboard from 'react-copy-to-clipboard'
import { FaCheck, FaCopy } from 'react-icons/fa6'
import { useAuth } from '../../contexts/Auth'
import { usePostHog } from 'posthog-js/react'
import { FaGraduationCap } from 'react-icons/fa'
import { useStreamingText } from '../../hooks/stream'
import Button from '../../Components/Buttons'
import useHumanizerCredits from '../../hooks/useHumanizerCredits'
import CheckForAi from './check-for-ai'

const sampleText =
	"Global warming refers to the long-term rise in Earth's average temperatures, primarily due to human activities that release greenhouse gases like carbon dioxide, methane, and nitrous oxide into the atmosphere. These gases trap heat from the sun, creating a 'greenhouse effect' that leads to the warming of the planet. The burning of fossil fuels, deforestation, and industrial processes are major contributors to this phenomenon. As global temperatures rise, the effects can be seen in more frequent and severe weather events, such as heatwaves, droughts, hurricanes, and wildfires. Additionally, global warming accelerates the melting of glaciers and polar ice caps, causing sea levels to rise, which threatens coastal communities and ecosystems. Addressing global warming requires a collective effort to reduce emissions, transition to renewable energy sources, and adopt sustainable practices to preserve the planet for future generations."

export function HumanizerEditor() {
	const [subscriptionModalOpen, setSubscriptionModalOpen] = useState(false)
	const [loginModalOpen, setLoginModalOpen] = useState(false)
	const [streamText, setStreamText] = useState(false)
	const { creditsAvailable } = useHumanizerCredits()
	const [copied, setCopied] = useState(false)
	const [editorState, setEditorState] = useAtom(editorAtom)
	const humanize = useHumanizeText({
		onSuccess: () => {
			setStreamText(true)
		},
	})
	const wordCount = useWordCount(editorState.textInputValue)
	const humanizedWordCount = useWordCount(editorState.humanizedText ?? '')
	const isPremium = useIsPremium()
	const humanizedTextToDisplay = useStreamingText(editorState.humanizedText ?? '', streamText, () => {})

	const { currentUser } = useAuth() as any
	const posthog = usePostHog()
	const pathname = window.location.pathname

	useEffect(() => {
		setEditorState({
			...editorState,
			aiDetectionScore: undefined,
			previousHumanization: undefined,
		})
	}, [editorState.textInputValue])

	useEffect(() => {
		setEditorState({
			...editorState,
			humanizedText: undefined,
		})
	}, [])

	useEffect(() => {
		if (copied) {
			const timeoutId = setTimeout(() => {
				setCopied(false)
			}, 2000)
			return () => clearTimeout(timeoutId)
		}
	}, [copied])

	const openModalWithDelay = (setModalOpenFunction: (open: boolean) => void, callback?: () => void) => {
		setEditorState((editorState) => ({
			...editorState,
			isLoadingHumanizeText: true,
		}))
		setTimeout(() => {
			setEditorState((editorState) => ({
				...editorState,
				isLoadingHumanizeText: false,
			}))
			setModalOpenFunction(true)
			if (callback) callback()
		}, 500)
	}

	const _humanize = () => {
		if (wordCount < 30) {
			toast.error('Please enter at least 30 words.', {
				position: 'top-center',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'light',
				transition: Bounce,
			})
			return
		}
		posthog.capture('humanize', {
			num_words: wordCount,
		})

		if (isPremium || wordCount <= creditsAvailable) {
			humanize(false)
		} else {
			posthog.capture('credits-exceeded')
			openModalWithDelay(setSubscriptionModalOpen)
		}
	}

	const accountModals = useMemo(
		() => (
			<>
				<UpgradeModals
					subscriptionModalOpen={subscriptionModalOpen}
					loginModalOpen={loginModalOpen}
					setSubscriptionModalOpen={(open: boolean) => {
						setSubscriptionModalOpen(open)
					}}
					setLoginModalOpen={(open: boolean) => {
						setLoginModalOpen(open)
					}}
					premiumPath={false}
					noMoreCredits
				/>
			</>
		),
		[subscriptionModalOpen, loginModalOpen]
	)

	const copyButton = useMemo(() => {
		return (
			<CopyToClipboard text={editorState.humanizedText ?? ''} onCopy={() => setCopied(true)}>
				<div className={`${humanizedWordCount === 0 ? 'pointer-events-none' : 'cursor-pointer'}`}>
					<span className="relative">
						<FaCheck
							className={`transition-opacity text-xl top-[-10px] absolute right-[-12px] ${
								copied ? 'opacity-100' : 'opacity-0'
							}`}
						/>
						<FaCopy
							className={`transition-opacity top-[-10px] absolute right-[-12px] text-2xl ${
								copied ? 'opacity-0' : 'opacity-100'
							}`}
						/>
					</span>
				</div>
			</CopyToClipboard>
		)
	}, [copied, editorState.humanizedText, wordCount])

	return (
		<>
			{accountModals}
			<div className="max-h-[680px] min-h-[650px] bg-white rounded-xl border border-gray-300 shadow-lg flex flex-col w-full">
				<EditorTopSection />
				<div className="grid md:grid-cols-2 grow md:h-[580px] h-[680px]">
					<div className="border-r-2 border-gray-300 h-full flex flex-col min-h-0 relative">
						{editorState.textInputValue.length === 0 && (
							<div className="absolute top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-full flex gap-2">
								{pathname !== '/humanizer' && (
									<Button type="ghost" onClick={() => setEditorState({ ...editorState, textInputValue: sampleText })}>
										Try sample text
									</Button>
								)}
								<Button
									type="outline"
									onClick={() =>
										navigator.clipboard
											.readText()
											.then((text) => setEditorState({ ...editorState, textInputValue: text }))
									}
								>
									Paste text
								</Button>
							</div>
						)}
						<textarea
							placeholder="Type your text here..."
							className="w-full resize-none outline-none p-4 grow overflow-y-auto rounded-bl-xl leading-relaxed min-h-60"
							onChange={(e) => {
								setEditorState({ ...editorState, textInputValue: e.target.value })
							}}
							value={editorState.textInputValue}
						/>
						<div className="sticky bottom-0 mx-4 flex flex-row flex-wrap items-center justify-between bg-white py-4 gap-3">
							{currentUser ? (
								<div className={`font-semibold ${wordCount > MAX_HUMANIZER_WORD_COUNT ? 'text-danger-default' : ''}`}>
									{wordCount} / {MAX_HUMANIZER_WORD_COUNT} Words
								</div>
							) : (
								<div className="font-semibold">
									{wordCount} Words = {editorState.textInputValue.length} Chars
								</div>
							)}
							<div className="flex flex-row items-center gap-2">
								<Button
									type="default"
									className="text-white"
									onClick={_humanize}
									disabled={
										editorState.isLoadingHumanizeText ||
										wordCount > MAX_HUMANIZER_WORD_COUNT ||
										editorState.aiDetectionScoreLoading
									}
								>
									<div className="flex items-center gap-2">
										<FaGraduationCap /> {editorState.isLoadingHumanizeText ? 'Humanizing' : 'Humanize'}
									</div>
								</Button>
								<CheckForAi text={editorState.textInputValue} onHumanizeClick={_humanize} />
							</div>
						</div>
					</div>
					<div className="flex flex-col h-full min-h-0 border-t border-gray-500 md:border-t-0">
						<div className="grow bg-white p-4 pt-3 rounded-br-xl overflow-y-auto leading-relaxed min-h-60">
							{editorState.humanizedText ? (
								humanizedTextToDisplay
							) : (
								<span className="text-gray-600">
									{editorState.isLoadingHumanizeText ? 'Loading...' : 'Your humanized content will appear here.'}
								</span>
							)}
						</div>
						<div className="sticky bottom-0 bg-white flex justify-between p-4 items-center rounded-xl">
							<div className="font-semibold">{humanizedWordCount} Words</div>
							<div className="flex gap-4 items-center">
								{editorState.humanizedText && (
									<CheckForAi
										text={editorState.humanizedText}
										label="Check result for AI"
										humanized
										onHumanizeClick={_humanize}
									/>
								)}
								<Button
									type="primary"
									onClick={() => navigator.clipboard.writeText(editorState.humanizedText ?? '')}
									disabled={!editorState.humanizedText}
									className="mx-2 mb-1"
								>
									{copyButton}
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
