import { useAtom } from 'jotai'
import { useMemo } from 'react'
import ScoreIndicator from './score-indicator'
import { editorAtom } from './constants'
import { useFeatureFlagVariantKey } from 'posthog-js/react'
import { FEATURE_FLAGS } from '../../hooks/featureFlags'

const AIDetectionScore = ({ detector = false }: { detector?: boolean }) => {
	const [editorState] = useAtom(editorAtom)
	const humanizerV2FF = useFeatureFlagVariantKey(FEATURE_FLAGS.HUMANIZER_V2)

	const detectors =
		humanizerV2FF === 'test'
			? [
					{ name: 'openai', displayName: 'OpenAI' },
					{ name: 'gptzero', displayName: 'GPTZero' },
					{ name: 'writer', displayName: 'Writer' },
					{ name: 'crossplag', displayName: 'Crossplag' },
					{ name: 'copyleaks', displayName: 'Copyleaks' },

					{
						name: 'sapling',
						displayName: 'Sapling',
					},
					{ name: 'contentatscale', displayName: 'ContentAtScale' },
					{ name: 'zerogpt', displayName: 'ZeroGPT' },
			  ]
			: [
					{ name: 'scoreOpenAI', displayName: 'OpenAI' },
					{ name: 'scoreGptZero', displayName: 'GPTZero' },
					{ name: 'scoreWriter', displayName: 'Writer' },
					{ name: 'scoreCrossPlag', displayName: 'Crossplag' },
					{ name: 'scoreCopyLeaks', displayName: 'Copyleaks' },

					{
						name: 'scoreSapling',
						displayName: 'Sapling',
					},
					{ name: 'scoreContentAtScale', displayName: 'ContentAtScale' },
					{ name: 'scoreZeroGPT', displayName: 'ZeroGPT' },
			  ]

	const scores = useMemo(() => {
		return detectors.map((detector) => {
			if (!editorState.aiDetectionScore) {
				return {
					name: detector.name,
					displayName: detector.displayName,
					score: 0,
				}
			}
			if (detector.name in editorState.aiDetectionScore) {
				return {
					name: detector.name,
					displayName: detector.displayName,
					score: Math.floor(
						// @ts-ignore
						editorState.aiDetectionScore[detector.name].ai * 100
					),
				}
			}
			return {
				name: detector.name,
				displayName: detector.displayName,
				score: 0,
			}
		})
	}, [editorState.aiDetectionScore])

	if (!editorState.showDetectionBox) {
		return null
	}

	return (
		<div
			className={`bg-gray-100 border ${
				detector
					? 'border-gray-500 border-b-0 rounded rounded-bl-none rounded-br-none'
					: 'border-gray-400 border-t-0 border-b-0'
			} p-1`}
		>
			<div className="rounded-none bg-gray-100 border border-gray-200">
				<div className="flex gap-4 items-center w-full justify-between overflow-x-auto">
					{scores.map((detector, index) => {
						return (
							<div className="flex gap-2 items-center flex-nowrap" key={index}>
								<ScoreIndicator score={detector.score} />
								<div className="text-sm text-nowrap">
									<span className="font-semibold">{detector.displayName}</span>
								</div>
							</div>
						)
					})}
				</div>
			</div>
		</div>
	)
}

export default AIDetectionScore
